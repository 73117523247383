<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Qualifications & Preferences Plus Additional Information Edit
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver ref="EditQualAndPrefInfo" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="EditQualAndPrefInfo"
          >
            <CRow class="mt-3">
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Full Time</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="full_time"
                      :value="jobs.full_time"
                      :options="
                        options && options['workingHours']
                          ? options['workingHours']
                          : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Part Time</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="part_time"
                      :value="jobs.part_time"
                      :options="
                        options && options['workingHours']
                          ? options['workingHours']
                          : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Permanent Work</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="permanent_work"
                      :value="jobs.permanent_work"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Temporary Work</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="temporary_work"
                      :value="jobs.temporary_work"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >Maximum age ({{ jobs.maximum_age }} yrs)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <v-slider
                      name="maximum_age"
                      :min="20"
                      :max="70"
                      :value="jobs.maximum_age"
                      @change="handleSlider"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >Type of Contract / Length</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="type_of_contract"
                      :value="jobs.type_of_contract"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow>
              <CCol
                class="mb-3 col-md-6"
                v-if="
                  jobs.working_hours && jobs.working_hours.detail_number != null
                "
              >
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >Working Hours (Per Week)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      :rules="{ max_value_hours: 48 }"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="working_hours"
                        type="Number"
                        :min="0"
                        :max="48"
                        :value="
                          jobs.working_hours && jobs.working_hours.detail_number
                        "
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                class="mb-3 col-md-6"
                v-if="
                  jobs.working_days && jobs.working_days.detail_number != null
                "
              >
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >Working Days (per week)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      :rules="{ max_value_days: 7 }"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="working_days"
                        type="Number"
                        :min="0"
                        :max="7"
                        :value="
                          jobs.working_days && jobs.working_days.detail_number
                        "
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow v-if="jobs.call_on && jobs.call_on.detail_number != null">
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">On-Call Rota</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="call_on_radio"
                      :value="jobs.call_on_radio"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >Hospital/ Clinic Based</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="organisation_type"
                        :value="
                          jobs.organisation_type
                        "
                        @input="handleInput"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3" v-if="jobs.call_on_radio">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >On-call hours (optional)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="call_on"
                      type="Number"
                      :value="jobs.call_on && jobs.call_on.detail_number"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import AddJob from "@/containers/AddJobs/AddJob";
import { max_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("max_value_hours", {
  ...max_value,
  message: "Value cannot be greater than 48",
});
extend("max_value_days", {
  ...max_value,
  message: "Value cannot be greater than 7",
});

export default {
  mixins: [AddJob],
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileData: {},
      payload: {},
      jobs: {},
    };
  },
  computed: {
    isFetching() {
      if (Object.keys(this.jobInfo).length) {
        let jobData = this.jobInfo;
        let working_days = [];
        let working_hours = [];
        let call_on = [];
        let call_on_radio = null;
        let first_visit_working_hours = false;
        let first_visit_working_days = false;
        let first_visit_call_on = false;
        if (jobData?.job_details && Object.keys(jobData?.job_details).length) {
          working_days = jobData?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 1;
          });
          working_days.length ? (first_visit_working_days = false) : null;
          working_hours = jobData?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 2;
          });
          working_hours.length ? (first_visit_working_hours = false) : null;
          call_on = jobData?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 6;
          });
          if (call_on.length) {
            call_on_radio = true;
            first_visit_call_on = false;
          }
        }
        if (Object.keys(this.getOrgJobDetails).length) {
          if (!working_days.length) {
            working_days = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 1;
            });
            if (working_days.length) {
              first_visit_working_days = true;
              working_days[0].detail_text = working_days[0].description;
            }
          }
          if (!working_hours.length) {
            working_hours = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 2;
            });
            if (working_hours.length) {
              first_visit_working_hours = true;
              working_hours[0].detail_text = working_hours[0].description;
            }
          }
          if (!call_on.length) {
            call_on = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 6;
            });
            call_on.length ? (first_visit_call_on = true) : null;
          }
        }
        this.jobs = {
          ...jobData,
          working_days: working_days[0],
          working_hours: working_hours[0],
          call_on_radio: call_on_radio,
          call_on: call_on_radio ? call_on[0] : call_on[1],
          first_visit_working_days: first_visit_working_days,
          first_visit_working_hours: first_visit_working_hours,
          first_visit_call_on: first_visit_call_on,
          organisation: jobData?.organisation
            ? {
                label: jobData?.organisation.name,
                code: jobData?.organisation.organisation_id,
              }
            : null,
        };
      }
      return true;
    },
    options() {
      return {
        workingHours: [
          { id: "yes", label: "Yes" },
          { id: "no", label: "No" },
          { id: "flexible", label: "Flexible" },
        ],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
  },
  mounted() {
    if (Object.keys(this.jobInfo?.job_details).length < 3) {
      this.fetchOrgJobDetails({
        organisation_id: this.jobInfo.organisation?.organisation_id,
      });
    }
  },
  methods: {
    handleInput(name, value) {
      let data = value;
      if (name === "working_hours") {
        data = {
          ...this.jobs.working_hours,
          detail_number: value,
          detail_text: `${value} hour(s) working a week`,
        };
      } else if (name === "working_days") {
        data = {
          ...this.jobs.working_days,
          detail_number: value,
          detail_text: `${value} day(s) working week`,
        };
      } else if (name === "call_on") {
        data = {
          ...this.jobs.call_on,
          detail_number: value,
          detail_text: `${value} hour(s) working for Call-On-Rota`,
        };
      }
      Vue.set(this.jobs, name, data);
      Vue.set(this.payload, name, data);
    },
    handleSlider: debounce(function(value) {
      Vue.set(this.jobs, "maximum_age", value);
      Vue.set(this.payload, "maximum_age", value);
    }, 500),
    async onSubmit() {
      const isValid = await this.$refs.EditQualAndPrefInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      } else {
        this.jobs.first_visit_working_hours && !this.payload.working_hours
          ? (this.payload.working_hours = this.jobs.working_hours)
          : null;
        this.jobs.first_visit_working_days && !this.payload.working_days
          ? (this.payload.working_days = this.jobs.working_days)
          : null;
        this.jobs.call_on_radio &&
        this.jobs.first_visit_call_on &&
        !this.payload.call_on
          ? (this.payload.call_on = this.jobs.call_on)
          : null;
        this.onSave();
      }
    },
    onSave() {
      const { job_id, customer_uid, organisation_uid } = this.jobs;
      if (Object.keys(this.payload).length) {
        let finalPayload = {
          job_id: this.jobs?.job_id,
          customer_uid: this.jobs?.customer_uid,
          organisation_id: this.jobs.organisation?.code,
          full_time: this.jobs?.full_time,
          part_time: this.jobs?.part_time,
          permanent_work: this.jobs?.permanent_work,
          temporary_work: this.jobs?.temporary_work,
          maximum_age: this.jobs?.maximum_age,
          type_of_contract: this.jobs?.type_of_contract,
        };
        let payloadArray = Object.keys(this.payload);
        let AdditionalPayload = [];
        payloadArray.forEach((item) => {
          if (
            item === "working_hours" ||
            item === "working_days" ||
            item === "call_on"
          ) {
            AdditionalPayload.push(this.payload[item]);
          }
        });
        let appendAction = [];
        if (
          this.jobs.call_on_radio == false &&
          !this.jobs.first_visit_call_on
        ) {
          const { job_detail_id } = this.jobs.call_on;
          appendAction = this.deleteAdditionalInfo({
            job_id,
            job_detail_id,
          });
        }
        if (AdditionalPayload?.length) {
          AdditionalPayload.forEach((val) => {
            const { organisation_job_detail_id } = val;
            let exist = this.jobInfo?.job_details?.some(
              (element) =>
                element.organisation_job_detail_id == organisation_job_detail_id
            );
            if (exist) {
              appendAction = this.updateAdditionalInfo({
                job_id,
                val: val,
              });
            } else {
              appendAction = this.createAdditionalInfo({
                job_id,
                val,
              });
            }
          });
        }
        Promise.all([appendAction]).then((res) => {
          this.modalCallBack(true, finalPayload);
        });
      } else {
        this.modalCallBack(false);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>
