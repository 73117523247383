<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="xl"
    >
      <template #header>
        <h6 class="modal-title">
          {{ `${isEdit ? "Edit" : "Create"} Pre Screening Question` }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          >{{ isEdit ? "Update" : "Save" }}</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <CRow>
          <CCol md="7">
            <h5 class="text-primary">Pre Screening Questions</h5>
            <CCard>
              <CCardBody>
                <p
                  v-if="questions.length === 0"
                  class="cursor-pointer text-muted text-center mb-0"
                  @click="addOneQuestion"
                >
                  Click here to Add Empty Question
                </p>
                <ValidationObserver
                  ref="preScreeningForm"
                  v-slot="{ handleSubmit }"
                >
                  <form
                    @submit.prevent="handleSubmit(onSubmit)"
                    name="preScreeningForm"
                  >
                    <CRow v-for="(question, index) in questions" :key="index">
                      <CCol md="12" class="d-flex pl-0">
                        <div class="mx-3">
                          <span class="d-flex align-items-baseline handle">
                            <span
                              class="font-weight-bold text-muted text-nowrap"
                              >Q {{ index + 1 }}.</span
                            ></span
                          >
                          <div class="text-center">
                            <i
                              class="fa-solid fa-trash-can text-danger"
                              v-if="questions.length > 1 || isEdit"
                              @click="openDeleteModal(index)"
                            ></i>
                          </div>
                        </div>

                        <CRow class="w-100">
                          <CCol md="4" class="mb-2">
                            <CRow>
                              <label
                                class="col-lg-12 col-md-12 required"
                                :name="`lbl_type_${index}`"
                                >Type</label
                              >
                              <CCol md="12">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <Select
                                    :name="`question_type_id_${index}`"
                                    :value="question.question_type_id"
                                    :options="
                                      options && options['question_type']
                                        ? options['question_type']
                                        : []
                                    "
                                    @change="handleChangeSelect"
                                    :multiple="false"
                                    :clearable="false"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol md="8" class="mb-2">
                            <CRow>
                              <label
                                class="col-lg-12 col-md-12 required"
                                :name="`lbl_qus_${index}`"
                                >Question</label
                              >
                              <CCol md="12">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    :name="`question_${index}`"
                                    :value="question.question"
                                    @change="handleInput"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol md="4" class="mb-2 mt-auto">
                            <CRow>
                              <CCol md="8" sd="8">Mandatory</CCol>
                              <CCol md="4">
                                <CSwitch
                                  :name="`is_mandatory_${index}`"
                                  color="success"
                                  :checked="question.is_mandatory"
                                  shape="pill"
                                  variant="3d"
                                  @change.native="
                                    handleChangeCheck(
                                      `is_mandatory_${index}`,
                                      $event
                                    )
                                  "
                                />
                              </CCol>
                              <CCol md="12" class="d-flex align-items-center">
                                <span class="mr-3"></span>
                              </CCol>
                              <CCol md="12"> </CCol>
                            </CRow>
                          </CCol>
                          <CCol
                            md="8"
                            class="mb-2"
                            v-if="question.question_type_id"
                          >
                            <CRow
                              v-if="
                                [6, 7].includes(question.question_type_id.code)
                              "
                            >
                              <label class="col-lg-12 col-md-12 required"
                                >Value
                                <i
                                  class="fa-solid fa-circle-info mx-1"
                                  v-c-tooltip="{
                                    content:
                                      'Please type Comma or Enter key to confirm each of the values!',
                                  }"
                                ></i
                              ></label>
                              <CCol md="12">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <v-select
                                    :name="`option_${index}'`"
                                    :value="question.options"
                                    @input="
                                      (val) => handleChangeInput(val, index)
                                    "
                                    :taggable="true"
                                    :multiple="true"
                                    :clearable="true"
                                    :map-keydown="handlers"
                                    :clearSearchOnSelect="true"
                                    :components="{ OpenIndicator }"
                                  >
                                    <template v-slot:no-options>
                                      <em style="opacity: 0.5"></em>
                                    </template>
                                  </v-select>
                                  <small class="has-error" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </CCol>
                            </CRow>
                            <CRow v-if="question.question_type_id.code === 5">
                              <label class="col-lg-12 col-md-12 required"
                                >Max Value</label
                              >
                              <CCol md="12">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    :name="`max_rating_count_${index}`"
                                    :value="question.max_rating_count"
                                    type="Number"
                                    @change="handleInput"
                                    :min="1"
                                    :onkeydown="restrictedKeys"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                      <div class="d-flex w-100">
                        <hr
                          :class="
                            questions.length === index + 1 ? 'w-45' : 'w-95'
                          "
                        />
                        <div>
                          <a
                            class="btn px-0 mx-1 text-success"
                            title="Add"
                            @click="addOneQuestion"
                            v-if="questions.length === index + 1"
                            ><i class="fa-solid fa-circle-plus fs-20"></i
                          ></a>
                        </div>
                        <hr
                          :class="{ 'w-45': questions.length === index + 1 }"
                        />
                      </div>
                    </CRow>
                  </form>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="5">
            <div v-if="questions.length && questions[0].question">
              <h5 class="text-primary">Preview</h5>
              <CCard class="preview">
                <CCardBody>
                  <CRow
                    v-for="(question, index) in questions"
                    :key="index"
                    class="mb-2"
                  >
                    <CCol md="12" v-if="question.question">
                      <CRow>
                        <label
                          class="col-lg-12 col-md-12"
                          :class="{ required: question.is_mandatory }"
                          ><span class="mr-2 text-muted"
                            >Q {{ index + 1 }}.</span
                          ><span class="font-weight-bold">{{
                            question.question
                          }}</span></label
                        >
                        <CCol md="12" v-if="question.question_type_id">
                          <Select
                            v-if="
                              [6, 7].includes(question.question_type_id.code)
                            "
                            :name="`value_${index}`"
                            :options="question.options || []"
                            :multiple="question.question_type_id.code === 7"
                          />
                          <RadioButton
                            v-if="question.question_type_id.code === 1"
                            :name="`value_${index}`"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                          />
                          <TextareaInput
                            v-if="question.question_type_id.code === 2"
                            :name="`value_${index}`"
                          />
                          <TextInput
                            v-if="question.question_type_id.code === 3"
                            :name="`value_${index}`"
                            type="number"
                          />
                          <Rating
                            v-if="question.question_type_id.code === 5"
                            :name="`value_${index}`"
                            :isNumberRating="true"
                            :maxRating="question.max_rating_count"
                            :value="0"
                          />
                          <DatePicker
                            v-if="question.question_type_id.code === 4"
                            :name="`value_${index}`"
                            format="DD-MM-YYYY"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </div>
          </CCol>
        </CRow>
      </div>
    </CModal>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import Rating from "@/components/reusable/Fields/Rating";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import Modal from "@/components/reusable/Modal";

import Vue from "vue";
import _ from "lodash";
export default {
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    preScreeningQuestions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
    Rating,
    Modal,
  },
  data() {
    return {
      questions: [
        {
          is_mandatory: false,
        },
      ],
      deletedQuestions: [],
      payload: [],
      OpenIndicator: {
        render: (createElement) => null,
      },
      restrictedKeys:
        "javascript: return [8,9,46].includes(event.keyCode) ? true : !isNaN(Number(event.key))",
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        index: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "questionTypes",
      "isFetchingFilter",
      "getCustomerId",
      "getOrgIDFromAccessToken",
    ]),
    options() {
      return {
        question_type: this.questionTypes || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    isEdit() {
      return this.preScreeningQuestions.length || false;
    },
  },
  methods: {
    ...mapActions(["initFetchingPreScreening", "showToast"]),
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    addOneQuestion() {
      this.questions.push({ is_mandatory: false });
    },
    openDeleteModal(index) {
      this.deleteConfirmModal.modalContent = `Do you want to Delete Question - ${
        index + 1
      }?`;
      this.deleteConfirmModal.index = index;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { index } = this.deleteConfirmModal;
        this.deleteQuestion(index);
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.index = null;
      } else {
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.index = null;
      }
    },
    deleteQuestion(index) {
      if (index > -1) {
        const { question_id } = this.questions[index];
        this.questions.splice(index, 1);
        this.payload.splice(index, 1);
        if (this.isEdit && question_id) {
          this.deletedQuestions.push(question_id);
        }
      }
    },
    draggChange({ moved }) {
      const { oldIndex, newIndex } = moved;
      this.array_move(this.payload, oldIndex, newIndex);
    },
    handleChangeSelect(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index], name, value);
      this.payload[index] = {
        ...this.payload[index],
        [name]: value?.code || value?.id || value,
      };
      if (value?.code === 5) {
        this.handleInput(`max_rating_count_${index}`, 5);
      }
    },
    handleInput(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index], name, value);
      this.payload[index] = {
        ...this.payload[index],
        [name]: value,
      };
    },
    handlers: (map, vm) => ({
      ...map,
      188: (e) => {
        e.preventDefault();
        vm.$nextTick(() => vm.$refs.search.focus());
        return vm.typeAheadSelect();
      },
    }),
    decodeName(data) {
      if (data) {
        const split_index = data.lastIndexOf("_");
        const name = data.substr(0, split_index);
        const index = data.substr(split_index + 1) || null;
        return { name, index };
      }
      return data;
    },
    handleChangeInput(value, index) {
      const name = "options";
      Vue.set(this.questions[index], name, value);
      this.payload[index] = {
        ...this.payload[index],
        [name]: value,
      };
    },
    convertCase(data) {
      if (data) return _.startCase(data);
      return data;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    handleRadio(_name, value) {
      const { name, index } = this.decodeName(_name);
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.questions[index], name, val);
      this.payload[index] = {
        ...this.payload[index],
        [name]: val,
      };
    },
    handleChangeRating(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value,
      };
    },
    handleChangeCheck(_name, $event) {
      const { name, index } = this.decodeName(_name);
      const val = $event.target.checked;
      Vue.set(this.questions[index], name, val);
      this.payload[index] = {
        ...this.payload[index],
        [name]: val,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.preScreeningForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      const payload = this.payload.map((v) => {
        return {
          ...v,
          customer_uid: this.getCustomerId,
          organisation_id: this.getOrgIDFromAccessToken,
        };
      });
      const deletedQuestions = this.deletedQuestions;
      this.modalCallBack(true, { payload, deletedQuestions });
    },
  },
  mounted() {
    this.initFetchingPreScreening();
    if (this.preScreeningQuestions.length) {
      this.questions = this.preScreeningQuestions.map((v) => {
        return {
          question_type_id: {
            code: v?.question_type?.question_type_id,
            label: _.startCase(v?.question_type?.question_type_name),
          },
          question: v?.question,
          is_mandatory: v?.is_mandatory,
          options: v?.options,
          max_rating_count: v?.max_rating_count,
          question_id: v?.question_id,
        };
      });
      this.payload = this.preScreeningQuestions.map((v) => {
        return {
          question_type_id: v?.question_type?.question_type_id,
          question: v?.question,
          is_mandatory: v?.is_mandatory,
          options: v?.options,
          max_rating_count: v?.max_rating_count,
          question_id: v?.question_id,
        };
      });
    }
  },
};
</script>
<style lang="scss" scoped>
hr {
  align-self: center;
  background: #eee;
  height: 1px;
}

.w-45 {
  width: 45%;
}

.w-95 {
  width: 95%;
}

.button {
  background: transparent;
  border: 2px solid;
  padding: 10px 40px;
  font-size: 15px;
  text-align: center;
  margin: 20px 10px 10px 0;
  font-weight: bold;
  display: inline-block;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
ul.select li {
  list-style-type: circle;
}
.textarea {
  width: 80%;
  height: 50px;
  border: 1px solid #eee;
  border-radius: 5px;
}
.preview {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.fs-20 {
  font-size: 20px;
}
</style>