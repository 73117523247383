<template>
  <div>
    <a
      v-if="!isAccessingFromAddJob"
      class="d-flex btn pl-0 text-left"
      @click="
        ($parent.isExpand.internal = !$parent.isExpand.internal),
          $parent.checkExpandAll()
      "
    >
      <span class="pr-1">
        <em
          class="text-primary fas"
          :class="
            $parent.isExpand.internal ? 'fa-angle-down' : 'fa-angle-right'
          "
          alt="angle-down"
        />
      </span>
      <h5 class="text-primary">
        Internal: Attach document associated to this job
      </h5>
    </a>
    <div v-if="isAccessingFromAddJob || $parent.isExpand.internal">
      <CRow v-if="isEditable">
        <CCol md="7">
          <CRow class="row mb-3">
            <label name="lbl_document_type" class="col-lg-12 col-md-12">
              Associated Documents to be stored with Job
              <em
                class="fas fa-info-circle text-primary"
                style="
                  vertical-align: super;
                  font-size: smaller;
                  cursor: pointer;
                "
                v-c-tooltip="{
                  content: `Contact your Customer Admin if required document not available`,
                  appendToBody: true,
                }"
              />
            </label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                name="document_type"
                :value="uploadData.document_type || `--Select--`"
                @input="handleChangeSelect"
                :options="
                  options && options['document_type']
                    ? options['document_type']
                    : []
                "
                :taggable="false"
                :multiple="false"
                :clearable="true"
              />
            </div>
          </CRow>
          <CRow class="mb-3" v-if="uploadData.document_type">
            <label name="lbl_desc" class="col-md-12">Comments</label>
            <CCol md="12">
              <TextInput
                name="comments"
                :value="uploadData.comments"
                @input="handleInput"
              />
            </CCol>
            <div
              class="mx-3 mt-2 d-flex align-items-center"
              v-if="!isCustomType && filename"
            >
              <div class="text-muted">File Name:</div>
              <div class="d-flex ml-2">
                <CButton
                  class="btn-link mb-1 cursor-pointer p-0"
                  @click="
                    preview(uploadData.document_type.data.organisation_document)
                  "
                >
                  {{ filename }}
                </CButton>
              </div>
            </div>
          </CRow>
          <CRow>
            <CCol md="6" class="d-flex">
              <CButton
                color="primary"
                class="px-4 f-12"
                @click="addDocumentModal"
                v-if="filename || uploadData.fileName"
              >
                ADD
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="5">
          <CRow class="mt-4 mb-2">
            <CCol
              md="12"
              class="d-flex"
              v-if="uploadData.document_type && isCustomType"
            >
              <input
                id="document-upload"
                name="upload-document"
                type="file"
                ref="fileref"
                :accept="arrayToString(fileExtention)"
                @change="selectedFile"
              />
              <a
                class="btn btn p-0 mx-2"
                v-if="isCustomType && uploadData.fileName"
                @click="clearDocument"
              >
                <CIcon
                  name="cil-x-circle"
                  class="text-primary"
                  style="margin-right: 20px"
                />
              </a>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CRow
        class="align-items-center documentScrolling document-table"
        v-if="jobDocumentActions.length"
      >
        <div v-if="data" class="multi-records col-lg-11">
          <div
            class="top-border d-flex"
            style="justify-content: space-between"
            v-for="(data, index) in jobDocumentActions"
            :key="index"
          >
            <div class="ml-2 mt-1 record" style="min-width: 90%">
              <CRow class="d-flex" style="margin-left: 2px">
                <div class="table col col-3">
                  <p class="head">Classification</p>
                  <p class="data">
                    {{ data | document_type }}
                  </p>
                </div>
                <div class="table col col-3">
                  <p class="head">Comments</p>
                  <p class="data">
                    {{ data.comments || "--" }}
                  </p>
                </div>
                <div class="table col col-3">
                  <p class="head">Uploaded Date</p>
                  <p class="data">
                    {{ formatDate(data.created_on) }}
                  </p>
                </div>
                <div class="table col col-3">
                  <p class="head">Document Name</p>
                  <p class="data">
                    {{ data.document_library.library_name }}
                  </p>
                </div>
                <div class="table col col-3">
                  <p class="head">File Name</p>
                  <p class="data">
                    {{ data | document_name }}
                  </p>
                </div>
                <div class="table col col-6" v-if="data.action_type == 'job'">
                  <p class="head">Do you want to attach Document to Advert?</p>
                  <p class="data">
                    <label
                      class="mr-2"
                      :key="index"
                      v-for="(item, index) in options.boolean"
                    >
                      <input
                        :id="`add_to_advert_${data.document_action_id}`"
                        :checked="data.add_to_advert === item.id"
                        type="radio"
                        :value="data.add_to_advert"
                        @input="toggleAddToAdvert(data)"
                      />
                      <span class="ml-2">{{ item.label }}</span>
                    </label>
                  </p>
                </div>
              </CRow>
            </div>

            <div
              class="
                widget-content-actions
                d-flex
                flex-column
                justify-content-center
              "
            >
              <button
                type="button"
                :disabled="disabledButton.includes(index)"
                class="btn icon hand-icon mb-1"
                v-if="getLink(data)"
                @click="fileDownload(data, index)"
              >
                <em class="icon fas fa-download" />
              </button>

              <button
                type="button"
                class="btn icon hand-icon mb-1"
                v-if="getLink(data)"
                @click="preview(data)"
              >
                <em class="fas fa-eye" />
              </button>

              <button
                class="btn icon hand-icon"
                v-if="data && isEditable"
                @click="deleteDocuments(data)"
              >
                <em class="fas fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </CRow>
      <p
        class="text-muted p-2 text-center"
        v-if="!jobDocumentActions.length && !isAccessingFromAddJob"
      >
        No Job Document Found
      </p>
    </div>
    <PreviewModal
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="confirmationModal.isPreview"
      :iframe="iframe"
      :size="confirmationModal.size"
      :closeOnBackdrop="confirmationModal.closeOnBackdrop"
    />
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
    <Modal
      :modalTitle="publishModal.modalTitle"
      :modalColor="publishModal.modalColor"
      :modalContent="publishModal.modalContent"
      :isShowPopup="publishModal.isShowPopup"
      :buttons="publishModal.buttons"
      :modalCallBack="addDocument"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import DocumentInfoEdit from "@/containers/CandidateDetailsTalentFind/EditForm/DocumentInfoEdit";
import Select from "@/components/reusable/Fields/Select.vue";
import PreviewModal from "@/components/reusable/PreviewModal";
import Modal from "@/components/reusable/Modal";
import pdf from "vue-pdf";
import TextInput from "@/components/reusable/Fields/TextInput";
import { BASE_URL } from "@/service_urls";
import CropperModal from "@/components/reusable/CropperModal";
import { Role, getScope } from "@/helpers/helper";

export default {
  components: {
    UploadDocument,
    Select,
    PreviewModal,
    DocumentInfoEdit,
    Modal,
    TextInput,
    CropperModal,
  },
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    isAccessingFromAddJob: {
      type: Boolean,
      default: () => false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      data: {},
      uploadData: {},
      isExpand: false,
      disabledButton: [],
      confirmationModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["Ok"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      publishModal: {
        modalColor: "primary",
        modalTitle: "Confirmation",
        modalContent: "Do you want to attach this Document to Advert?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
        isPreview: false,
        size: "md",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Notification",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "customerDocumentLibBasedOnType",
      "jobDocumentActions",
      "customerDocumentTypes",
      "documentExtensions",
      "currentUserType",
    ]),
    isCustomType() {
      if (this.uploadData?.document_type)
        return (
          this.uploadData?.document_type?.data?.request_type === "custom" ||
          false
        );
      return false;
    },
    filename() {
      if (this.uploadData?.document_type)
        return this.uploadData?.document_type?.data?.organisation_document
          ?.document_name;
      return null;
    },
    description() {
      if (this.uploadData?.document_type?.data?.document_library_id)
        return this.uploadData?.document_type?.data?.document_description;
      else if (this.uploadData?.document_type?.data?.customer_document_type_id)
        return this.uploadData?.document_type?.data?.description;
      return null;
    },
    options() {
      return {
        document_type: this.customerDocumentTypesWithCustom || [],
        boolean: [
          { label: "Yes", id: true },
          { label: "No", id: false },
        ],
      };
    },
    customerDocumentTypesWithCustom() {
      const candidate_type_id = this.profile?.candidate_type_id;
      const category = ["job"];
      return (
        (candidate_type_id &&
          this.customerDocumentLibBasedOnType({
            candidate_type_id,
            category,
          })) ||
        []
      );
    },
    fileExtention() {
      return (
        this.documentExtensions.map(({ label }) => `.${label.toLowerCase()}`) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "createDocumentAction",
      "updateDocumentAction",
      "getJobDocumentAction",
      "deleteDocumentAction",
      "fetchCustomerDocumentTypes",
      "downloadDocument",
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchDocumentExtension",
    ]),
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
    },
    arrayToString(data) {
      return data.join();
    },
    clearDocument() {
      this.$refs.fileref.value = "";
      if (this.uploadData.file) {
        Vue.delete(this.uploadData, "file");
        Vue.delete(this.uploadData, "fileName");
      }
    },
    initFetch() {
      const payload = {
        job_id: this.profile.job_id,
        action_type: "job",
        candidate_uid__isnull: true,
        organisation_id: this.profile?.organisation_uid,
      };
      this.getJobDocumentAction(payload);
      // To update advert after delete or upate
      this.$store.commit("LAST_FETCHED_JOB_ADVERT", null);
      this.fetchJobBenefitsAdvertDescByJobId({
        customer_id: this.profile?.customer_uid,
        job_id: this.profile.job_id,
      });
    },
    getLink(data) {
      return (
        data?.link_to_doc ||
        data?.document_library?.organisation_document?.link_to_doc ||
        null
      );
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return `.${parts[parts.length - 1]}`;
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        if (this.fileExtention.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.fileData = file;
            this.cropperModal.isShowPopup = true;
          } else {
            this.uploadData = {
              ...this.uploadData,
              file,
              fileName: file?.name,
            };
          }
        } else {
          let fileTypeError = this.arrayToString(this.fileExtention);
          this.$refs.fileref.value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
          return;
        }
      } else {
        this.$refs.fileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    addDocumentModal(){
      this.publishModal.isShowPopup = true;
    },
    addDocument(action) {
      if (action =="Yes"){
        this.publishModal.isShowPopup = false;
      const document_library_id =
        this.uploadData?.document_type?.data?.document_library_id;
      const customer_document_type_id =
        this.uploadData?.document_type?.data?.customer_document_type_id;
      const isAlreadyExist = document_library_id
        ? this.jobDocumentActions.some(
            (val) =>
              val?.document_library_id === document_library_id &&
              this.uploadData?.document_type?.data?.request_type != "custom"
          )
        : false;
      if (isAlreadyExist) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Already Exist!",
        });
        this.uploadData = {};
        return;
      }
      if (this.uploadData?.document_type) {
        const { job_id, organisation_uid } = this.profile;
        const action_type = "job";
        const actioned_by = "recruiter";
        const { comments } = this.uploadData;
        let payload = {
          job_id,
          action_type,
          actioned_by,
          comments,
          organisation_id: organisation_uid,
        };
        if (document_library_id) payload = { ...payload, document_library_id };

        if (this.isCustomType)
          payload = {
            ...payload,
            customer_document_type_id,
            file: this.uploadData?.file,
            fileName: this.uploadData?.fileName,
          };
        this.createDocumentAction(payload).then((res) => {
          this.initFetch();
          this.uploadData = {};
          if (this.$refs.fileref) this.$refs.fileref.value = "";
          if (res?.document_action_id) {
            const { document_action_id } = res;
            this.publishModal.document_action_id = document_action_id;
          }
        });
        return;
      }
    }
      else{
        this.publishModal.isShowPopup = false;
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      if (name === "document_type") {
        const description = this.uploadData?.document_type?.data?.comments;
        this.handleInput("comments", description);
      }
    },
    fileDownload(data, index) {
      this.disabledButton.push(index);
      let { link_to_doc, document_name } = data;
      if (!link_to_doc) {
        link_to_doc =
          data?.document_library?.organisation_document?.link_to_doc;
        document_name =
          data?.document_library?.organisation_document?.document_name;
      }
      let url = link_to_doc.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        this.disabledButton.shift();
      });
      return false;
    },
    preview(data) {
      let { link_to_doc, document_ext } = data;
      if (!link_to_doc) {
        link_to_doc =
          data?.document_library?.organisation_document?.link_to_doc;
        document_ext =
          data?.document_library?.organisation_document?.document_ext;
      }
      var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      var _base_domain = "https://" + window.location.host;
      this.iframe.url =
        _baseUrl.substring(0, _baseUrl.indexOf("/api")) + link_to_doc;
      const ext = document_ext?.document_ext.toUpperCase();
      if (["PNG", "JPG", "JPEG", "GIF"].includes(ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + link_to_doc;
        // this.iframe.src = "http://localhost" + data.link;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(_base_domain + link_to_doc);
        /* this.iframe.pdf.src = pdf.createLoadingTask(
          "http://localhost" + data.link
        );*/
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (
        ext === "TXT" ||
        ext === "EPS" ||
        ext === "TIF" ||
        ext === "TIFF" ||
        ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.confirmationModal.isPreview = false;
        this.confirmationModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          link_to_doc +
          "";
        /*this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=http://localhost" +
          data.link +
          "";*/
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.confirmationModal.isPreview = false;
      this.confirmationModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${
        data?.customer_document_type?.type_name ||
        data?.document_library?.customer_document_type?.type_name
      } ?`;
      this.deleteConfirmModal.data = data;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        const document_action_id =
          this.deleteConfirmModal.data.document_action_id;
        this.deleteDocumentAction({ document_action_id }).then((res) => {
          this.initFetch();
        });
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    formatDate(data) {
      if (data) return moment(data).format("DD MMM YYYY");
      return "--";
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.uploadData = { ...this.uploadData, fileName };
        this.uploadData.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    toggleAddToAdvert(data) {
      let payload = {
        action_type: data?.action_type,
        actioned_by: "recruiter",
        document_action_id: data.document_action_id,
        add_to_advert: !data.add_to_advert,
      };
      this.updateDocumentAction(payload).then(() => {
        this.initFetch();
      });
    },
  },
  filters: {
    document_type(data) {
      if (data?.document_library)
        return (
          data?.document_library?.customer_document_type?.type_name || "--"
        );
      return data?.customer_document_type?.type_name || "--";
    },
    description(data) {
      if (data?.document_library)
        return data?.document_library?.document_description || "--";
      return data?.customer_document_type?.description || "--";
    },
    document_name(data) {
      if (data?.document_name) return data?.document_name || "--";
      return (
        data?.document_library?.organisation_document?.document_name || "--"
      );
    },
  },
  mounted() {
    if (!this.documentExtensions.length) {
      this.fetchDocumentExtension();
    }
  },
};
</script>
<style>
.gray-box {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 9px;
}
</style>
