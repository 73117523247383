<template>
  <div class="pre-screening-response">
    <CModal
      v-if="!scratchpad.isShowPopup"
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5>Pre Screening Responses</h5>
        <CButtonClose
          aria-label="Close"
          class="close"
          @click="modalCallBack(false)"
        />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <CListGroup>
          <div
            v-for="preScreeningResponse in preScreeningResponses"
            :key="preScreeningResponse.candidate_uid"
          >
            <CListGroupItem class="py-2 border-1" href="javascript:">
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0">
                  <a
                    class="btn-link"
                    @click="navToCandidate(preScreeningResponse.candidate_uid)"
                    >{{ preScreeningResponse.candidate_name }} (ID-{{
                      preScreeningResponse.candidate_display_uid
                    }})</a
                  >
                  <span
                    class="text-primary cursor-pointer mx-1"
                    v-c-tooltip="{
                      content: 'Add to Scratch Pad',
                      appendToBody: true,
                      placement: 'top',
                    }"
                  >
                    <i
                      class="fa-solid fa-circle-plus fs-14"
                      @click="addToScratch(preScreeningResponse)"
                    ></i>
                  </span>
                </p>
                <a
                  class="btn-link text-decoration-none p-2"
                  @click="showCollapse(preScreeningResponse.candidate_uid)"
                >
                  {{
                    collapseIndex === preScreeningResponse.candidate_uid
                      ? "- Collapse"
                      : "+ Expand"
                  }}
                </a>
              </div>
            </CListGroupItem>
            <CCollapse
              :show="collapseIndex === preScreeningResponse.candidate_uid"
            >
              <CCard class="p-1 m-0 rounded-0">
                <CCardBody>
                  <div v-if="isFetchingTransactions" class="text-center">
                    <CSpinner
                      v-if="isFetchingTransactions"
                      color="primary"
                      class="mx-5 justify-content-center"
                      style="width: 2rem; height: 2rem"
                    />
                  </div>
                  <div v-else>
                    <PreScreeningTransaction 
                    :preScreeningTransactions="preScreeningResponse.transactions"/>
                    <!-- <CRow
                      v-for="transaction in preScreeningResponse.transactions"
                      :key="transaction.id"
                      class="mb-2"
                    >
                      <CCol md="12" v-if="transaction.job_question">
                        <CRow>
                          <label class="col-lg-12 col-md-12">{{
                            transaction.job_question.question
                          }}</label>
                          <CCol md="12">
                            <Select
                              v-if="
                                [6, 7].includes(
                                  transaction.job_question.question_type_id
                                )
                              "
                              :name="`options_response_${preScreeningResponse.candidate_uid}`"
                              :value="transaction.options_response"
                              :clearable="false"
                              :multiple="
                                transaction.job_question.question_type_id === 7
                              "
                              :disabled="true"
                            />
                            <RadioButton
                              v-if="
                                transaction.job_question.question_type_id === 1
                              "
                              :name="`yes_no_response_${preScreeningResponse.candidate_uid}`"
                              :value="transaction.yes_no_response"
                              :options="
                                options && options['boolean']
                                  ? options['boolean']
                                  : []
                              "
                              :disabled="true"
                            />
                            <TextareaInput
                              v-if="
                                transaction.job_question.question_type_id === 2
                              "
                              :name="`text_area_response_${preScreeningResponse.candidate_uid}`"
                              :value="transaction.text_area_response"
                              :disabled="true"
                            />
                            <TextInput
                              v-if="
                                transaction.job_question.question_type_id === 3
                              "
                              :name="`numeric_response_${preScreeningResponse.candidate_uid}`"
                              type="number"
                              :value="transaction.numeric_response"
                              :disabled="true"
                            />
                            <Rating
                              v-if="
                                transaction.job_question.question_type_id === 5
                              "
                              :name="`rating_count_${preScreeningResponse.candidate_uid}`"
                              :isNumberRating="true"
                              :maxRating="
                                transaction.job_question.max_rating_count
                              "
                              :value="transaction.rating_count"
                              :disabled="true"
                            />
                            <DatePicker
                              v-if="
                                transaction.job_question.question_type_id === 4
                              "
                              :name="`date_response_${preScreeningResponse.candidate_uid}`"
                              format="DD-MM-YYYY"
                              :value="transaction.date_response"
                              :disabled="true"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow> -->
                  </div>
                </CCardBody>
              </CCard>
            </CCollapse>
          </div>
        </CListGroup>
      </div>
    </CModal>    
    <ScratchPadModal
      v-if="scratchpad.isShowPopup"
      :todo="scratchpad.todo"
      :isShowPopup="scratchpad.isShowPopup"
      @modalCallBack="scratchPadModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import Select from "@/components/reusable/Fields/Select.vue";
// import TextInput from "@/components/reusable/Fields/TextInput";
// import PhoneInput from "@/components/reusable/Fields/PhoneInput";
// import TextareaInput from "@/components/reusable/Fields/TextareaInput";
// import DatePicker from "@/components/reusable/Fields/DatePicker";
// import RadioButton from "@/components/reusable/Fields/RadioButton";
// import Rating from "@/components/reusable/Fields/Rating";
import ScratchPadModal from "@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal";
import PreScreeningTransaction from "@/components/reusable/PreScreeningTransaction";
export default {
  props: {
    jobInfo: {
      type: Object,
      default: {},
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // Select,
    // TextInput,
    // PhoneInput,
    // TextareaInput,
    // DatePicker,
    // RadioButton,
    // Rating,
    ScratchPadModal,
    PreScreeningTransaction,
  },
  data() {
    return {
      collapseIndex: null,
      scratchpad: {
        isShowPopup: false,
        todo: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      "isFetchingFilter",
      "preScreeningResponses",
      "isFetchingTransactions",
    ]),
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      "fetchJobPreScreeningResponses",
      "fetchJobCandidateTransactions",
      "setCandidateListBackUrl",
      "postScratchPad",
    ]),
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
    showCollapse(candidate_uid) {
      if (this.collapseIndex === candidate_uid) {
        this.collapseIndex = null;
        return;
      }
      this.collapseIndex = candidate_uid;
      const isTransactionAvl = this.preScreeningResponses.some(
        (val) =>
          val.candidate_uid === candidate_uid && val?.transactions?.length
      );
      if (!isTransactionAvl) {
        const { job_id } = this.jobInfo;
        this.fetchJobCandidateTransactions({ candidate_uid, job_id });
      }
    },
    navToCandidate(candidate_id) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push({ path: `/candidate/${candidate_id}` });
    },
    addToScratch(item){      
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip-old");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);    
      this.scratchpad.todo = {
        title: `Candidate - ${this.toTitleCase(
          item.candidate_name
        )} (ID-${item.candidate_display_uid})`,
        description: `<p>Please review candidate <a href='/candidate/${
          item.candidate_uid
        }'>${this.toTitleCase(item.candidate_name)} (ID-${item.candidate_display_uid})</a></p>`,
      };
      this.scratchpad.isShowPopup = true;
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    scratchPadModalCallBack(action, payload) {
      if (action && payload) {
        this.postScratchPad(payload);
      }
      this.scratchpad.isShowPopup = false;
      this.scratchpad.todo = null;
    },
  },
  mounted() {
    const { job_id } = this.jobInfo;
    this.fetchJobPreScreeningResponses(job_id);
  },
};
</script>
<style lang="scss" scoped>
.btn-link:hover {
  text-decoration: underline;
  color: red;
}
.no-focus:focus,
.no-focus:active {
  outline: none !important;
  box-shadow: none !important;
}
</style>