<template>
  <div class="jobBenefits-info-section" v-if="!re_render_the_html_content">
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0 text-left"
        @click="
          ($parent.isExpand.salary = !$parent.isExpand.salary),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <em
            class="text-primary fas"
            :class="
              $parent.isExpand.salary ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary">Salary & Benefits</h5>
      </a>
    </div>
    <div v-if="$parent.isExpand.salary" class="gray-box">
      <CRow class="pb-2 profileView">
        <CCol lg="6">
          <label class="text-gray">Salary Range</label>
          <span class="text-black">
            {{
              profile.salary_min && profile.salary_max
                ? `${
                    profile.salary_min_text || profile.salary_min
                  } ${salary_currency} to ${
                    profile.salary_max_text || profile.salary_max
                  } ${salary_currency}`
                : "--"
            }}
          </span>
          <span
            v-if="isSalaryRangePublished"
            v-c-tooltip="'Salary displayed in public job advert'"
            class="status"
            style="color: green"
          >
            <em class="fas fa-check-circle"></em>
          </span>
          <span
            v-if="!isSalaryRangePublished"
            v-c-tooltip="'Salary not displayed in public job advert'"
            class="status"
            style="color: grey"
          >
            <em class="fas fa-times-circle"></em>
          </span>
        </CCol>
        <CCol lg="6">
          <label class="text-gray">Target Salary</label>
          <span class="text-black"
            >{{ profile.target_salary_text || profile.target_salary || "--" }}
            {{ target_currency }}</span
          >
        </CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol>
          <label class="text-gray">Benefits</label>
          <CCol col="7" class="text-black" v-if="!benefits">--</CCol>
          <CCol class="text-black" v-else v-html="benefits"> </CCol>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import AddJob from "../../AddJobs/AddJob";
export default {
  mixins: [AddJob],
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    isEditable:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      isExpand: false,
      re_render_the_html_content: false,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedJobBenefitsAndAdvertDesc",
      "getJobDetailsById",
      "getCurrencyForSelectedID",
    ]),
    target_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.target_salary_currency_id
      ).map((val) => val.label)[0];
    },
    salary_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.salary_currency_id
      ).map((val) => val.label)[0];
    },
    isSalaryRangePublished() {
      return this.profile?.preferred_in_advert?.includes("salary")
        ? true
        : false;
    },
    benefits() {
      let stdBenefits = [];
      let addBenefits = [];
      let benefits = this.getSelectedJobBenefitsAndAdvertDesc?.benefits
        ? this.getSelectedJobBenefitsAndAdvertDesc?.benefits?.map((val) => ({
            benefit_type: val?.benefit_types,
            comments: val.comments,
            description: val.description,
            display_order: val?.organisation_job_benefit_display_order,
          }))
        : this.getJobDetailsById?.benefits?.map((val) => ({
            benefit_type: val?.organisation_job_benefit?.benefit_types,
            comments: val.comments,
            description: val.description,
            display_order: val?.organisation_job_benefit?.display_order,
          }));
      benefits?.forEach((item) => {
        item?.benefit_type == "standard_benefits"
          ? stdBenefits.push(item)
          : addBenefits.push(item);
      });
      stdBenefits = stdBenefits.sort(
        (a, b) => a.display_order - b.display_order
      );
      addBenefits = addBenefits.sort(
        (a, b) => a.display_order - b.display_order
      );
      benefits = stdBenefits.concat(addBenefits);
      if (benefits?.length) {
        let list = "";
        benefits.forEach((item) => {
          list += `<li>${item.description}${
            item.comments ? ` -${item.comments}` : ""
          }</li>`;
        });
        return list;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["updateJobByDetailPage", "getJobById"]),
    editSalaryBenefitsInfo() {
      this.EditModal.isShowPopup = true;
    },
    salaryBenefitsModalCallBack(action, data) {
      if (action && data != null) {
        let { organisation_id, ...rest } = data;
        let { job_id, customer_uid } = this.profile;
        this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...rest,
        }).then((res) => {
          this.getJobById(job_id);
          this.EditModal.isShowPopup = false;
        });
      }
      this.EditModal.isShowPopup = false;
    },
  },
  watch: {
    // To re-render the html content which updates tooltip properly
    isSalaryRangePublished() {
      this.re_render_the_html_content = true;
      setInterval(() => {
        this.re_render_the_html_content = false;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.gray-box {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 9px;
}
</style>
