<template>
  <div class="jobQualification-info-section">
    <LoadingOverlay v-if="isFetching" />
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0 text-left"
        @click="
          ($parent.isExpand.qualification = !$parent.isExpand.qualification),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <em
            class="text-primary fas"
            :class="
              $parent.isExpand.qualification
                ? 'fa-angle-down'
                : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary">Key Information & Preferences</h5>
      </a>
      <div
        v-if="$parent.isExpand.qualification"
        class="d-flex justify-content-end action-wrapper"
      >
        <a
          class="px-2 btn"
          v-if="isEditable"
          @click="editqualificationPreferencesInfo"
        >
          <em class="fas fa-pencil-alt" alt="Edit" />
        </a>
      </div>
    </div>
    <div v-if="$parent.isExpand.qualification" class="gray-box">
      <CRow>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Full Time</CCol>
            <CCol lg="6" class="text-black">{{
              profile.full_time | firstLetterCaps
            }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Part Time</CCol>
            <CCol lg="6" class="text-black">{{
              profile.part_time | firstLetterCaps
            }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Permanent Job</CCol>
            <CCol lg="6" class="text-black">{{
              profile.permanent_work | yesOrNo
            }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Temporary Job</CCol>
            <CCol lg="6" class="text-black">{{
              profile.temporary_work | yesOrNo
            }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Maximum Age</CCol>
            <CCol lg="6" class="text-black">{{
              isCandidateSupplierFromAccessToken
                ? "N/A"
                : profile.maximum_age
                ? `${profile.maximum_age} years`
                : "--"
            }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Contract Type/Length</CCol>
            <CCol lg="6" class="text-black">{{
              profile.type_of_contract || "--"
            }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Working Days (per week)</CCol>
            <CCol lg="6" class="text-black">{{ workingDays }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Working hours (per week)</CCol>
            <CCol lg="6" class="text-black">{{ workingHours }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6" v-if="onCallRota">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">On-Call Rota</CCol>
            <CCol lg="6" class="text-black">{{ onCallRota }}</CCol>
          </CRow>
        </CCol>
        <CCol v-if="!isCandidateSupplierFromAccessToken" lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Hospital/ Clinic Based</CCol>
            <CCol lg="6" class="text-black">{{ hospital_clinic_based }}</CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">Total Budget</CCol>
            <CCol lg="6" class="text-black">
              {{ allocatedBudget }} {{ total_budget_currency }}
            </CCol>
          </CRow>
        </CCol>
        <CCol lg="6">
          <CRow class="pb-2 profileView">
            <CCol lg="6" class="text-gray">No. of positions Available</CCol>
            <CCol lg="6" class="text-black">{{ positionsAvailable }}</CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
    <EditQualAndPrefInfo
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="qualAndPrefModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import AddJob from "../../AddJobs/AddJob";
import EditQualAndPrefInfo from "./EditJobDetailSection/EditQualificationPreferences";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  mixins: [AddJob],
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    isEditable:{
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditQualAndPrefInfo,
  },
  data() {
    return {
      isExpand: false,
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters(["getJobDetailsById", "isCandidateSupplierFromAccessToken", "currentUserType","getCurrencyForSelectedID"]),
    workingDays() {
      let working_days = this.profile?.job_details?.filter(
        (element) => element.organisation_job_detail?.job_detail_type_id == 1
      );
      return working_days?.length
        ? `${working_days[0].detail_number} day(s)`
        : "--";
    },
    workingHours() {
      let working_hours = this.profile?.job_details?.filter(
        (element) => element.organisation_job_detail?.job_detail_type_id == 2
      );
      return working_hours?.length
        ? `${working_hours[0].detail_number} hour(s)`
        : "--";
    },
    onCallRota() {
      let on_call_rota = this.profile?.job_details?.filter(
        (element) => element.organisation_job_detail?.job_detail_type_id == 6
      );
      return on_call_rota?.length
        ? `${on_call_rota[0].detail_number} hour(s) working for Call-On-Rota`
        : "--";
    },
    hospital_clinic_based() {
      return (
        this.getJobDetailsById?.organisation?.organisation_type
          ?.organisation_type || "--"
      );
    },
    allocatedBudget() {
      return this.profile?.allocated_budget || "--"
    },
    positionsAvailable() {
      return this.profile?.positions_available || "--";
    },
    total_budget_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.allocated_budget_currency_id
      ).map((val) => val.label)[0];
    },
  },
  filters: {
    yesOrNo(data) {
      if (data != null) return data ? "Yes" : "No";
      return "N/A";
    },
    firstLetterCaps(data) {
      if ((data == "n/a") | (data == null)) return "N/A";
      return data.charAt(0).toUpperCase() + data.slice(1);
    },
  },
  methods: {
    ...mapActions(["updateJobByDetailPage", "getJobById"]),
    editqualificationPreferencesInfo() {
      this.EditModal.isShowPopup = true;
    },
    qualAndPrefModalCallBack(action, data) {
      if (action && data != null) {
        let { organisation_id, ...rest } = data;
        let { job_id, customer_uid } = this.profile;
        this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...rest,
        }).then((res) => {
          this.getJobById(job_id);
          this.EditModal.isShowPopup = false;
        });
      }
      this.EditModal.isShowPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-box {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 9px;
}
</style>
