<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0 text-left"
        @click="
          ($parent.isExpand.advertising = !$parent.isExpand.advertising),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <em
            class="text-primary fas"
            :class="
              $parent.isExpand.advertising ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary">Job Status and Advertising</h5>
      </a>
      <div
        v-if="$parent.isExpand.advertising"
        class="d-flex justify-content-end action-wrapper"
      >
        <a class="px-2 btn" v-if="isEditable" @click="editAdvertisingInfo">
          <i class="fas fa-pencil-alt" alt="Edit" />
        </a>
      </div>
    </div>
    <div v-if="$parent.isExpand.advertising" class="gray-box">
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Job Created By</CCol>
        <CCol col="6" class="text-black">{{ jobCreatedBy }}</CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Job Status</CCol>
        <CCol
          v-if="jobStatus == 'Active' || jobStatus == 'Draft'"
          col="6"
          class="text-black"
          >{{ jobStatus }}
        </CCol>
        <CCol v-else col="6" class="text-black">
          <span
            v-if="
              jobStatusUpdate &&
                jobStatusUpdate.status &&
                jobStatusUpdate.sub_status
            "
          >
            <strong
              >{{
                `${jobStatusUpdate.status} -  ${
                  jobStatusUpdate.sub_status_id == 72
                    ? "Approval Send"
                    : jobStatusUpdate.sub_status
                }`
              }}
            </strong>
            By
            <strong>{{ `${jobStatusUpdate.update_by}` }}</strong>
            On
            <strong>{{ `${jobStatusUpdate.updated_on}` }}</strong>
          </span>
          <span v-else> {{ "--" }}</span>
        </CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Job Approved By</CCol>
        <CCol col="6" class="text-black">{{
          jobApprovedBy &&
          jobApprovedBy.approved_by &&
          jobApprovedBy.approved_on
            ? `${jobApprovedBy.approved_by} On ${jobApprovedBy.approved_on}`
            : "--"
        }}</CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Published Date</CCol>
        <CCol col="6" class="text-black">{{
          jobApprovedBy && jobApprovedBy.approved_on
            ? jobApprovedBy.approved_on
            : "--"
        }}</CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Closed Date</CCol>
        <CCol col="6" class="text-black"> N/A </CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Reason [Comments]</CCol>
        <CCol col="6" class="text-black"> N/A </CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Candidate Recruited</CCol>
        <CCol col="6" class="text-black"> N/A </CCol>
      </CRow>
      <CRow class="pb-2 profileView">
        <CCol col="6" class="text-gray">Advertising Location</CCol>
        <CCol col="6" class="text-black">{{ advertisingLocation }}</CCol>
      </CRow>
    </div>
    <EditAdvertisingInfo
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="advertisingModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import EditAdvertisingInfo from "./EditJobDetailSection/EditAdvertisingInfo";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    isEditable:{
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditAdvertisingInfo,
  },
  data() {
    return {
      isExpand: false,
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getAdLocationForSelectedID", "getEnumLabels", "currentUserType"]),
    advertisingLocation() {
      let data = this.getAdLocationForSelectedID(
        this.profile?.advertisement_location
      ).map((val) => val.label);
      return data?.length ? data.join(", ") : null || "--";
    },
    jobCreatedBy() {
      let created_by = this.profile?.created_by_user;
      if (created_by) {
        let first_name = created_by?.first_name;
        let last_name = created_by?.surname;
        let created_on = this.profile?.created_on
          ? moment(this.profile?.created_on).format("DD-MMM-YYYY")
          : null;
        return created_on && first_name && last_name
          ? `${first_name} ${last_name} On ${created_on}`
          : `--`;
      } else return "--";
    },
    jobApprovedBy() {
      return this.profile?.job_status?.status == "Active" &&
        this.profile?.approval_audits?.length
        ? _(this.profile?.approval_audits)
            .filter((action) => {
              return action.status == "Active" && action.status_id == 29;
            })
            .map(({ initiated_by_user, initiated_date }) => {
              let first_name = initiated_by_user?.first_name;
              let last_name = initiated_by_user?.surname;
              return {
                approved_by:
                  first_name && last_name
                    ? `${first_name}  ${last_name}`
                    : "--",
                approved_on: initiated_date
                  ? moment(initiated_date).format("DD-MMM-YYYY")
                  : "--",
              };
            })
            .value()[0]
        : null;
    },
    jobStatus() {
      return this.profile?.job_status?.status || null;
    },
    jobStatusUpdate() {
      let latest_job_update = this.profile?.approval_audits?.length
        ? _(this.profile?.approval_audits).maxBy("initiated_date")
        : null;
      if (latest_job_update) {
        return {
          status: latest_job_update?.status || null,
          status_id: latest_job_update?.status_id || null,
          sub_status: latest_job_update?.sub_status || null,
          sub_status_id: latest_job_update?.sub_status_id || null,
          updated_on: latest_job_update?.initiated_date
            ? moment(latest_job_update?.initiated_date).format("DD-MMM-YYYY")
            : "--",
          update_by:
            latest_job_update?.initiated_by_user?.first_name &&
            latest_job_update?.initiated_by_user?.surname
              ? `${latest_job_update?.initiated_by_user?.first_name} ${latest_job_update?.initiated_by_user?.surname}`
              : "--",
        };
      } else return "--";
    },
  },
  methods: {
    ...mapActions([
      "updateJobByDetailPage",
      "getJobById",
      "fetchEnumLabels",
      "showLoader",
      "hideLoader",
    ]),
    editAdvertisingInfo() {
      if (!this.getEnumLabels("advertisementlocation")?.length) {
        this.showLoader();
        this.fetchEnumLabels("advertisementlocation").then(() => {
          this.hideLoader();
          this.EditModal.isShowPopup = true;
        });
      } else {
        this.EditModal.isShowPopup = true;
      }
    },
    advertisingModalCallBack(action, data) {
      if (action && data != null) {
        let { organisation_id, ...rest } = data;
        let { job_id, customer_uid } = this.profile;
        this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...rest,
        }).then((res) => {
          this.getJobById(job_id);
          this.EditModal.isShowPopup = false;
        });
      }
      this.EditModal.isShowPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-box {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 9px;
}
</style>
