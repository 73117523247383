<template>
  <div class="pre-screening">
    <a
      class="d-flex btn pl-0 text-left"
      @click="
        ($parent.isExpand.prescreening = !$parent.isExpand.prescreening),
          $parent.checkExpandAll()
      "
    >
      <span class="pr-1">
        <em
          class="text-primary fas"
          :class="
            $parent.isExpand.prescreening ? 'fa-angle-down' : 'fa-angle-right'
          "
          alt="angle-down"
        />
      </span>
      <h5 class="text-primary">Pre Screening Questions</h5>
    </a>
    <div v-if="$parent.isExpand.prescreening" class="mx-3">
      <a
        v-if="!isCandidateSupplierFromAccessToken"
        class="btn-link cursor-pointer h6 d-block text-hover"
        @click="openPreScreeningModal"
      >
        Create/Update Pre Screening Questions
      </a>
      <a
        class="btn-link cursor-pointer h6 d-block text-hover"
        v-if="preScreeningQuestions.length"
        @click="openPreview"
      >
        View Pre Screening Questions
      </a>
      <p
        v-else-if="isCandidateSupplierFromAccessToken"
        class="text-center text-muted h6"
      >
        No Pre Screening Questions Found
      </p>
      <a
        v-if="preScreeningQuestions.length"
        class="btn-link cursor-pointer h6 d-block text-hover"
        @click="openResponses"
      >
        View Pre Screening Responses
      </a>
    </div>
    <PreScreeningModal
      v-if="preScreeningModal.isShowPopup"
      :isShowPopup="preScreeningModal.isShowPopup"
      :jobInfo="preScreeningModal.job"
      :preScreeningQuestions="preScreeningModal.preScreeningQuestions"
      @modalCallBack="preScreeningModalCallBack"
    />
    <PreviewPreScreening
      v-if="previewPreScreeningModal.isShowPopup"
      :isShowPopup="previewPreScreeningModal.isShowPopup"
      :jobInfo="previewPreScreeningModal.job"
      :preScreeningQuestions="preScreeningQuestions"
      :isPreview="true"
      @modalCallBack="previewPreScreeningModalCallBack"
    />
    <PreScreeningResponse
      v-if="preScreeningResponseModal.isShowPopup"
      :isShowPopup="preScreeningResponseModal.isShowPopup"
      :jobInfo="preScreeningResponseModal.job"
      @modalCallBack="preScreeningResponseModalCallBack"
    />
  </div>
</template>
<script>
import PreScreeningModal from "@/containers/JobsDetailedView/JobDetailSection/EditJobDetailSection/PrescreeningModal";
import PreviewPreScreening from "@/components/reusable/PreviewPreScreening";
import PreScreeningResponse from "@/containers/JobsDetailedView/JobDetailSection/PreScreening/PreScreeningResponses";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreScreeningModal,
    PreviewPreScreening,
    PreScreeningResponse,
  },
  data() {
    return {
      preScreeningModal: {
        isShowPopup: false,
        job: null,
      },
      previewPreScreeningModal: {
        isShowPopup: false,
        job: null,
      },
      preScreeningResponseModal: {
        isShowPopup: false,
        job: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "preScreeningQuestions",
      "isCandidateSupplierFromAccessToken",
    ]),
  },
  methods: {
    ...mapActions([
      "createJobQuestion",
      "deleteJobQuestion",
      "updateJobQuestion",
      "getPreScreeningQuestions",
      "showToast",
    ]),
    openPreScreeningModal() {
      this.preScreeningModal.isShowPopup = true;
      this.preScreeningModal.job = this.profile;
      this.preScreeningModal.preScreeningQuestions = this.preScreeningQuestions;
    },
    openPreview() {
      this.previewPreScreeningModal.isShowPopup = true;
      this.previewPreScreeningModal.job = this.profile;
    },
    previewPreScreeningModalCallBack(action) {
      this.previewPreScreeningModal.isShowPopup = false;
    },
    preScreeningModalCallBack(action, data) {
      if (action && data) {
        const { job_id } = this.profile;
        const { payload, deletedQuestions } = data;
        if (this.preScreeningModal.preScreeningQuestions.length) {
          let appendAction = [];
          if (deletedQuestions.length) {
            deletedQuestions.forEach((question_id) => {
              appendAction = [
                ...appendAction,
                this.deleteJobQuestion(question_id),
              ];
            });
          }
          const updatePayload = payload.filter(
            ({ question_id }) => question_id
          );
          const uploadPayload = payload.filter((v) => !v?.question_id);
          if (updatePayload.length) {
            appendAction = [
              ...appendAction,
              this.updateJobQuestion({ payload: updatePayload, job_id }),
            ];
          }
          if (uploadPayload.length) {
            appendAction = [
              ...appendAction,
              this.createJobQuestion({ payload: uploadPayload, job_id }),
            ];
          }
          Promise.all(appendAction).then((res) => {
            if (res) {
              this.showToast({
                class: "bg-success text-white",
                message: "Pre Screening Question Updated Successfully!",
              });
              this.getPreScreeningQuestions(job_id);
              this.preScreeningModal.isShowPopup = false;
              this.preScreeningModal.preScreeningQuestions = [];
            }
          });
        } else {
          this.createJobQuestion({ payload, job_id }).then((res) => {
            if (res.status == 200) {
              this.showToast({
                class: "bg-success text-white",
                message: "Pre Screening Question Created Successfully!",
              });
            }
            this.preScreeningModal.isShowPopup = false;
            this.preScreeningModal.preScreeningQuestions = [];
            this.getPreScreeningQuestions(job_id);
          });
        }
      } else {
        this.preScreeningModal.isShowPopup = false;
        this.preScreeningModal.preScreeningQuestions = [];
      }
    },
    openResponses() {
      this.preScreeningResponseModal.job = this.profile;
      this.preScreeningResponseModal.isShowPopup = true;
    },
    preScreeningResponseModalCallBack(action) {
      this.preScreeningResponseModal.isShowPopup = false;
      this.preScreeningResponseModal.job = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-hover:hover {
  text-decoration: underline !important;
}
</style>