<template>
  <div>
    <h6 class="text-primary pb-2">Professional Information</h6>
    <CRow>
    <CCol>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Type</CCol>
      <CCol lg="8" class="text-black">{{ type }}</CCol>
    </CRow>
    <div v-if="!isNurseOrAHP">
      <CRow class="profileView">
        <CCol lg="4" class="text-gray">Speciality</CCol>
        <CCol lg="8" class="text-black">{{ speciality }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="4" class="text-gray">Sub Speciality</CCol>
        <CCol lg="8" class="text-black">{{ subSpeciality }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="4" class="text-gray">Special Interest</CCol>
        <CCol lg="8" class="text-black">{{ specialInterests }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="4" class="text-gray">Suitable For</CCol>
        <CCol lg="8" class="text-black">{{ pqrLevels }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="4" class="text-gray">Highest Qualification</CCol>
        <CCol lg="8" class="text-black">
          {{
            candidate.highest_qualification_acronym
              ? candidate.highest_qualification_acronym
              : candidate.highest_qualification_name
          }}
        </CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="4" class="text-gray">Year & Country Obtained</CCol>
        <CCol lg="8" class="text-black">
          {{ qualificationYear }} & {{ qualificationCountry }}
        </CCol>
      </CRow>
    </div>
    <div v-else>
      <ProfessionalInfoNurseAH :candidate="candidate" />
    </div>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Regional License</CCol>
      <CCol lg="8" class="text-black">{{ activeLicense }}</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">{{
        candidate.available ? "Join Date/Availability" : "Available From"
      }}</CCol>
      <CCol lg="8" class="text-black">{{
        candidate.available ? expectedJoinDate : availableFrom
      }}</CCol>
    </CRow>
    <CRow class="profileView no-line">
      <CCol lg="4" class="text-gray">Languages</CCol>
      <CCol lg="8" class="text-black">{{ languages }}</CCol>
    </CRow>
    </CCol>
    </CRow>
    
    <h6 class="text-primary pb-2 mt-3">Personal Information</h6>
    <CRow>
    <CCol>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Title</CCol>
      <CCol lg="8" class="text-black">{{ title }}</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Known As</CCol>
      <CCol lg="8" class="text-black">{{ knownAs }}</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Date of Birth</CCol>
      <CCol lg="8" class="text-black">{{ dob }} ({{ age }} years)</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Nationality</CCol>
      <CCol col="8" class="text-black">{{ nationality }}</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Birthplace</CCol>
      <CCol col="8" class="text-black">{{ placeOfBirth }}</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Gender</CCol>
      <CCol lg="8" class="text-black">{{ gender }}</CCol>
    </CRow>
    <CRow class="profileView">
      <CCol lg="4" class="text-gray">Martial status</CCol>
      <CCol lg="8" class="text-black">{{ martialStatus }}</CCol>
    </CRow>
    <CRow class="profileView no-line">
      <CCol lg="4" class="text-gray">Dependents</CCol>
      <CCol lg="8" class="text-black">{{ dependents }}</CCol>
    </CRow>
    </CCol>
    </CRow>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import ProfessionalInfoNurseAH from "@/containers/JobsDetailedView/Information/ProfessionalInfoNurseAH";
export default {
  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },
  components: {
    ProfessionalInfoNurseAH,
  },
  computed: {
    ...mapGetters([
      "getSelectedCandidateDataForJobsList",
      "getCandidateSpecialInterest",
      "getLicense",
      "getCandidateLanguage",
      "getCandidateNonMedicalQuals",
    ]),
    type() {
      return this.candidate?.candidate_type?.candidate_type || "--";
    },
    speciality() {
      return this.candidate?.speciality?.speciality || "--";
    },
    subSpeciality() {
      return this.candidate?.sub_speciality?.sub_speciality || "--";
    },
    expectedJoinDate() {
      return this.candidate.expected_join_date
        ? moment(this.candidate.expected_join_date).format("DD-MMM-YYYY")
        : "--";
    },
    availableFrom() {
      return this.candidate.available_from
        ? moment(this.candidate.available_from).format("DD-MMM-YYYY")
        : "--";
    },
    activeLicense() {
      let activeLicense = this.getLicense
        ? this.getLicense.some((ele) => ele.current_license == true)
        : null;
      return activeLicense !== null ? (activeLicense ? "Yes" : "No") : "--";
    },
    languages() {
      let languages =
        this.getCandidateLanguage.map(({ language }) => {
          return {
            language_name: language !== null ? language.language : "--",
          };
        }) || [];
      if (languages?.length) {
        return languages
          .map((ele) => {
            return ` ${ele.language_name}`;
          })
          .toString();
      }
      return "--";
    },
    specialInterests() {
      return _(this.getCandidateSpecialInterest)
        .filter((speciality) => {
          return speciality.other_special_interest || speciality.special_interest
        })
        .map((speciality) => {
          return !speciality.other_special_interest
            ? speciality.special_interest?.special_interest
            : 'Other - ' + speciality.other_special_interest
        })
        .value()
        .join(', ') || "--"
    },
    knownAs() {
      return this.candidate?.known_as || "--";
    },
    title() {
      return this.candidate?.title?.title || "--";
    },
    gender() {
      return this.candidate?.gender?.gender || "--";
    },
    martialStatus() {
      return this.candidate?.marital_status || "--";
    },
    dob() {
      return this.candidate?.dob
        ? moment(this.candidate?.dob).format("DD-MM-YYYY")
        : "--";
    },
    age() {
      return this.candidate?.age || "--";
    },
    nationality() {
      return this.candidate?.nationality_names?.join(", ") || "--";
    },
    placeOfBirth() {
      return this.candidate?.birth_place?.country_name || "--";
    },
    dependents() {
      return this.candidate?.dependents || "--";
    },
    pqrLevels() {
      if (
        Array.isArray(this.candidate.pqr_levels) &&
        this.candidate?.pqr_levels?.length
      ) {
        const pqr_levels =
          this.candidate?.pqr_levels.filter(({ potential }) => !potential) ||
          [];
       if(pqr_levels.length){
         if(pqr_levels[0]?.working_candidate_level && !this.isAHP){
            const level = pqr_levels[0]?.working_candidate_level;
            const country_code = pqr_levels[0]?.pqr_detail?.pqr_header?.country?.country_code;
            return country_code ? `${level}(${country_code})`:level;
          }else if(pqr_levels[0]?.pqr_ahp_detail?.speciality?.speciality&& this.isAHP){
            const level = pqr_levels[0]?.pqr_ahp_detail?.speciality?.speciality;
            const country_code = pqr_levels[0]?.pqr_ahp_detail?.pqr_header?.country?.country_code;
            return country_code ? `${level}(${country_code})`:level;
          }
       }
        return "--";
      }
      return this.candidate.level_name || "--";
    },
    highestQualification() {
      let highestQualification = null;
      this.candidate.qualifications?.length &&
        this.candidate.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    qualificationCountry() {
      return this.highestQualification?.country_name || null;
    },
    qualificationYear() {
      return this.highestQualification?.year_awarded;
    },
    isNurseOrAHP() {
      return this.candidate?.candidate_type_id === 2 ||
        this.candidate?.candidate_type_id === 3
        ? true
        : false;
    },
    isAHP(){
      return this.candidate?.candidate_type_id === 3 || false;
    }
  },
};
</script>
